import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer'; // Require the polyfill before requiring any other modules.
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { isOnline, publicUrl } from '@utils/config/env';
import store from './store';
import 'ui/build/assets/bundle.css';
import './sentry';

const Homepage = React.lazy(() => import('@pages/Homepage'));
const SignPage = React.lazy(() => import('@pages/Sign'));
const ProdPage = React.lazy(() => import('@pages/Prod'));
const CartPage = React.lazy(() => import('@pages/Cart'));
const StorePage = React.lazy(() => import('@pages/Store'));
const OnSalePage = React.lazy(() => import('@pages/OnSale')); // version 3
const CartWaiting = React.lazy(() => import('@pages/Cart/CartWaiting'));
const CartCheckout = React.lazy(() => import('@pages/CartCheckout'));
const Reviews = React.lazy(() => import('@pages/Reviews'));
const Refund = React.lazy(() => import('@app/pages/Refund/index'));
const RefundDetails = React.lazy(() => import('@pages/Refund/RefundDetails'));
const RedirectToOrderDetail = React.lazy(() => import('@pages/Refund/RedirectToOrderDetail'));

const Root: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path={publicUrl} element={<Homepage />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/index" element={<Homepage />} />
        <Route path="/index/v1" element={<Homepage />} />
        <Route path="/sign" element={<SignPage />} />
        <Route path="/sign/:urlSignId" element={<SignPage />} />
        <Route path="/sign/:urlSignId.htm" element={<SignPage />} />
        <Route path="/sign/v1" element={<SignPage />} />
        <Route path="/sign/v1/:urlSignId" element={<SignPage />} />
        <Route path="/prod/:prodId" element={<ProdPage />} />
        <Route path="/prod/v1/:prodId" element={<ProdPage />} />
        <Route path="/prod/preview/v1/:prodId" element={<ProdPage />} />
        <Route path="/onsale" element={<OnSalePage />} />
        <Route path="/onsale/preview" element={<OnSalePage isPreview />} />
        <Route path="/store" element={<StorePage />} />
        <Route path="/store/:urlStoreId" element={<StorePage />} />
        <Route path="/store/v1/" element={<StorePage />} />
        <Route path="/store/v1/:urlStoreId" element={<StorePage />} />
        <Route path="/fsrwd/cart" element={<CartPage />} />
        <Route path="/fsrwd/cart/bidding" element={<CartPage />} />
        <Route path="/fsrwd/cart/PickCar/:storeId" element={<CartPage />} />
        <Route path="/fsrwd/cart/ItemCar/:prodId" element={<CartPage />} />
        <Route path="/waitingroom/index.html" element={<CartWaiting />} />
        <Route path="/waitingroom_stage/index.html" element={<CartWaiting />} />
        <Route path="/vip/reviews/:reviewsListType" element={<Reviews />} />
        <Route path="/fsrwd/cart/payinfo" element={<CartCheckout />} />
        <Route path="/fsrwd/cart/payinfo/bidding" element={<CartCheckout />} />
        <Route path="/fsrwd/cart/payinfo/PickCar/:storeId" element={<CartCheckout />} />
        <Route path="/fsrwd/cart/payinfo/ItemCar/:prodId" element={<CartCheckout />} />
        <Route path="/vip/refund/:orderId" element={<Refund />} />
        <Route path="/vip/refund/:orderId/detail" element={<RefundDetails />} />
        <Route path="/vip/refund/" element={<RedirectToOrderDetail />} />

        {!isOnline && (
          <>
            <Route path="/index/stage/v1" element={<Homepage />} />
            <Route path="/sign/stage/:urlSignId" element={<SignPage />} />
            <Route path="/sign/stage/v1/:urlSignId" element={<SignPage />} />
            <Route path="/onsale" element={<OnSalePage />} />
            <Route path="/onsale/preview" element={<OnSalePage isPreview />} />
            <Route path="/prod/stage/:prodId" element={<ProdPage />} />
            <Route path="/prod/stage/v1/:prodId" element={<ProdPage />} />
            <Route path="/store/stage/" element={<StorePage />} />
            <Route path="/store/stage/:urlStoreId" element={<StorePage />} />
            <Route path="/store/stage/v1/" element={<StorePage />} />
            <Route path="/store/stage/v1/:urlStoreId" element={<StorePage />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  </Provider>
);

const container = document.getElementById('root');

const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<Root />);
