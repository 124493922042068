// https://ecapi2.pchome.com.tw/cdn/ecshop/cateapi/v1.5/region&sign=h24%252Ffood&_callback=cb_ecshopCategoryRegion&5511507
import { SearchAutosuggestResponse } from '@app/definitionTypes/header/headerSearch';
import Fetch from '../utils/jsonp';

interface keywordType {
  searchText: string;
  limit: number;
  timestamp: string;
}

function fetchSuggestKeyword(options: keywordType): Promise<SearchAutosuggestResponse> {
  const { searchText = '', limit = 15, timestamp = '' } = options;
  // FIXME:無dev,stage 環境
  const url = `https://ecshweb.pchome.com.tw/search/v4.3/all/suggestwords?q=${searchText}&limit=${limit}&timestamp=${timestamp}`;

  return Fetch.jsonp({
    url,
    param: 'callback',
    callback: null,
  });
}

export { fetchSuggestKeyword };
