import { ecwebUrl, ecshwebUrl } from '@services/utils/domain-route';

const prodUrlList = ['Picwt', 'Pic2', 'Pic1', 'Prod', 'prod'];
const getBlockLink = (ElementType: string, Url: string): string => {
  let link = '';
  if (ElementType.toLowerCase() === 'url') {
    link = Url;
  } else if (ElementType.toLowerCase() === 'store') {
    link = ecwebUrl({ path: `/store/${Url}`, isPageUrl: true });
  } else if (prodUrlList.includes(ElementType)) {
    link = ecwebUrl({ path: `/prod/${Url}`, isPageUrl: true });
  } else if (ElementType === 'Search') {
    link = ecshwebUrl(`/search/v3.3/?q=${Url}`);
  } else if (ElementType === 'coupon') {
    link = ecwebUrl({ path: `/coupon/${Url}`, isPageUrl: true });
  } else if (ElementType === '') {
    // no url
    link = '';
  }
  return link;
};
export default getBlockLink;
