import lastProdReducer, { PersonalLastProdState } from '@commonFeatures/user/lastProdSlice';
import personalItemCartCountSlice, {
  PersonalItemCartCountState,
} from '@commonFeatures/user/personalItemCartSlice';
import videoReducer, { VideoState } from '@commonFeatures/video/videoSlice';
import loginMemberInformationSlice, {
  LoginMemberInformationStateName,
} from '@commonFeatures/user/loginMemberInformationSlice';
import enterpriseReducer, { EnterpriseState } from '@commonFeatures/enterpriseSlice';
import headerMenuReducer, { HeaderMenuState } from '@commonFeatures/header/headerMenuSlice';
import headerHoverMenuReducer, {
  HeaderHoverMenuState,
} from '@commonFeatures/header/headerHoverMenuSlice';
import commonHeaderMenuReducer, {
  CommonHeaderMenuState,
} from '@commonFeatures/common/commonHeaderSlice';
import headerConfigDataReducer, { ConfigDataState } from '@commonFeatures/header/configSlice';
import regionMenuSliceReducer, { RegionMenuState } from '@commonFeatures/sideMenu/regionMenuSlice';
import prodActivityMenuSliceReducer, {
  ProdActivityMenuState,
} from '@commonFeatures/sideMenu/prodActivityMenuSlice';
import couponRegionSliceReducer, {
  CouponRegionState,
} from '@commonFeatures/sideMenu/couponRegionSlice';
import newArrivalRegionSliceReducer, {
  NewArrivalRegionState,
} from '@commonFeatures/sideMenu/newArrivalRegionSlice';
import ecshopCategorySignSliceReducer, {
  EcshopCategorySignState,
} from '@commonFeatures/ecshopCategorySignSlice';
import regionInformationSliceReducer, {
  RegionInformationState,
} from '@commonFeatures/regionInformationSlice';
import noticeSummaryReducer, { NoticeSummaryState } from '@commonFeatures/noticeSummarySlice';
import burgerMenuSliceReducer, {
  BurgerMenuState,
} from '@commonFeatures/burgerMenu/burgerMenuSlice';
import recommendSliceReducer, { RecommendState } from '@commonFeatures/recommend/recommendSlice';
import prodDescSliceReducer, { ProdDescNameState } from '@commonFeatures/desc/prodDescSlice';
import prodIntroSliceReducer, { ProdIntroState } from '@commonFeatures/prodIntroSlice';
import prodAddSliceReducer, { ProdAddNameState } from '@commonFeatures/additional/prodAddSlice';
import prodFoodDescSliceReducer, {
  ProdFoodDescNameState,
} from '@commonFeatures/desc/prodFoodDescSlice';
import prodMedicSliceReducer, { ProdMedicNameState } from '@commonFeatures/desc/prodMedicSlice';
import searchMenuReducer, { SearchMenuState } from '@commonFeatures/searchMenu/searchMenuSlice';
import commonSmartAppStatusSliceReducer, {
  CommonSmartAppStatusState,
} from '@commonFeatures/common/commonSmartAppStatusSlice';
import activityStoreSliceReducer, { ActivityStoreState } from '@commonFeatures/activityStoreSlice';
import memberPiCampaignInformationSlice, {
  MemberPiCampaignInformationStateName,
} from '@commonFeatures/user/memberPiCampaignInformationSlice';
import prodTrackButtonSliceReducer, {
  ProdTrackButtonState,
} from '@commonFeatures/prod/prodTrackButtonSlice';
import prodMemberTrackListSliceReducer, {
  ProdMemberTrackListState,
} from '@commonFeatures/prod/prodMemberTrackSlice';
import logoutSliceReducer, { LogoutStateName } from '@commonFeatures/user/logoutSlice';
import searchAutosuggestSliceReducer, {
  SearchAutosuggest,
} from '@app/common/features/header/searchAutosuggestSlice';

export const commonReducers = {
  [HeaderMenuState.Name]: headerMenuReducer,
  [HeaderHoverMenuState.Name]: headerHoverMenuReducer,
  [CommonHeaderMenuState.Name]: commonHeaderMenuReducer,
  [RegionMenuState.Name]: regionMenuSliceReducer,
  [CouponRegionState.Name]: couponRegionSliceReducer,
  [NewArrivalRegionState.Name]: newArrivalRegionSliceReducer,
  [ProdActivityMenuState.Name]: prodActivityMenuSliceReducer,
  [ConfigDataState.Name]: headerConfigDataReducer,
  [EcshopCategorySignState.Name]: ecshopCategorySignSliceReducer,
  [NoticeSummaryState.Name]: noticeSummaryReducer,
  [RegionInformationState.Name]: regionInformationSliceReducer,
  [LoginMemberInformationStateName.Name]: loginMemberInformationSlice,
  [PersonalLastProdState.Name]: lastProdReducer,
  [PersonalItemCartCountState.Name]: personalItemCartCountSlice,
  [VideoState.Name]: videoReducer,
  [EnterpriseState.Name]: enterpriseReducer,
  [BurgerMenuState.Name]: burgerMenuSliceReducer,
  [RecommendState.Name]: recommendSliceReducer,
  [ProdDescNameState.Name]: prodDescSliceReducer,
  [ProdIntroState.Name]: prodIntroSliceReducer,
  [ProdAddNameState.Name]: prodAddSliceReducer,
  [ProdFoodDescNameState.Name]: prodFoodDescSliceReducer,
  [ProdMedicNameState.Name]: prodMedicSliceReducer,
  [SearchMenuState.Name]: searchMenuReducer,
  [CommonSmartAppStatusState.Name]: commonSmartAppStatusSliceReducer,
  [ActivityStoreState.Name]: activityStoreSliceReducer,
  [MemberPiCampaignInformationStateName.Name]: memberPiCampaignInformationSlice,
  [ProdMemberTrackListState.Name]: prodMemberTrackListSliceReducer,
  [ProdTrackButtonState.Name]: prodTrackButtonSliceReducer,
  [LogoutStateName.Name]: logoutSliceReducer,
  [SearchAutosuggest.Name]: searchAutosuggestSliceReducer,
};
