import { SearchAutosuggestResponse } from '@app/definitionTypes/header/headerSearch';
import { decodeHtmlEntity } from '@app/utils/decodeHtmlEntity';
import { SearchAutosuggestData, SuggestionType } from '@dfTypes/header/searchAutosuggest';
import { ecwebUrl } from '@services/utils/domain-route';

export const toAutosuggest = ({
  response,
}: {
  response: SearchAutosuggestResponse;
}): SearchAutosuggestData => {
  if (!response || response.length === 0) return [];

  const sectionSuggestions = response.map((item) => {
    const { Name, BU, Src, Rurl } = item;
    const isMcr: boolean = BU === 'mcr';
    const toType = (): SuggestionType => {
      if (Src) return SuggestionType.Brand; // 品牌
      if (isMcr) return SuggestionType.Mcr; // 比比昂日本購物 | mcr | mercari | bibian
      return SuggestionType.General;
    };

    return {
      name: decodeHtmlEntity(Name),
      businessName: isMcr ? '比比昂日本購物' : '24h',
      logoSrc: Src,
      rurl: toRurl({ isMcr, isBrand: Src !== '', Rurl, Name }),
      type: toType(),
    };
  });
  const generalSectionSuggestions = sectionSuggestions.filter(
    (suggestion) => suggestion.type === SuggestionType.General
  );
  const brandSectionSuggestions = sectionSuggestions.filter(
    (suggestion) => suggestion.type === SuggestionType.Brand
  );
  const mcrSectionSuggestions = sectionSuggestions.filter(
    (suggestion) => suggestion.type === SuggestionType.Mcr
  );

  const generalSection =
    generalSectionSuggestions.length === 0
      ? null
      : {
          title: SuggestionType.General,
          suggestions: generalSectionSuggestions,
        };

  const brandSection =
    brandSectionSuggestions.length === 0
      ? null
      : {
          title: SuggestionType.Brand,
          suggestions: brandSectionSuggestions,
        };

  const mcrSection =
    mcrSectionSuggestions.length === 0
      ? null
      : {
          title: SuggestionType.Mcr,
          suggestions: mcrSectionSuggestions,
        };

  return [brandSection, generalSection, mcrSection].filter(Boolean);
};

const toRurl = ({
  isMcr,
  isBrand,
  Rurl,
  Name,
}: {
  isMcr: boolean;
  isBrand: boolean;
  Rurl: string;
  Name: string;
}): string => {
  if (isMcr) {
    return `${ecwebUrl({ path: `/search/?q=${Name}&scope=mcr`, isPageUrl: true })}`;
  }
  if (isBrand) {
    return Rurl;
  }
  return `${ecwebUrl({ path: `/search/?q=${Name}`, isPageUrl: true })}`;
};
